import {COLLABORATE} from "~/helpers/navigation-items.js";

export const FEATURES = [
    // publish_view_schedule
    {
        to: '/features/bulk-scheduling',
        icon: 'ic:round-schedule-send',
        name: 'Bulk Scheduling',
        description: 'Schedule multiple posts at once to save time. Plan your content in advance.',
        category: 'publish_view_schedule',
    },

    {
        to: '/features/calendar-view',
        icon: 'material-symbols:calendar-month-rounded',
        name: 'Calendar View',
        description: 'Plan your content by viewing it in a calendar.',
        category: 'publish_view_schedule',
    },

    {
        to: '/features/feed-view',
        icon: 'material-symbols:calendar-view-day-rounded',
        name: 'Feed View',
        description: 'View published and scheduled posts in a feed view.',
        category: 'publish_view_schedule',
    },
    /*{
        to: '/features/rss-feed',
        icon: 'carbon:rss',
        name: 'RSS Feed',
        description: 'Automatically share new blog posts or articles from your favorite websites.',
        category: 'publish_view_schedule',
    },*/

    // collaboration
    {
        to: '/features/workspaces',
        icon: 'ic:round-workspaces',
        name: 'Workspaces',
        description: 'Organize your content by creating workspaces for different projects or clients.',
        category: 'collaboration',
    },

    {
        to: '/features/suggested-posts',
        icon: COLLABORATE.icon,
        name: 'Suggested Posts',
        description: 'Get suggested posts from your team, audience, friends, or anyone else, simply by sharing a public link.',
        category: 'collaboration',
    },

    // ai
    {
        to: '/features/ai-assist',
        icon: 'streamline:artificial-intelligence-spark-solid',
        name: 'AI Assist',
        description: 'Let AI help you write better captions and hashtags for your posts and increase engagement.',
        category: 'ai',
    },

    {
        to: '/features/templates',
        icon: 'mingcute:report-forms-fill',
        name: 'Templates',
        description: 'Save time by utilizing templates and letting AI do the work for you.',
        category: 'ai',
    },

    // engagement
    {
        to: '/features/first-comment',
        icon: 'ic:round-comment',
        name: 'First comment',
        description: 'Increase engagement by adding the first comment to your posts.',
        category: 'engagement',
    },

    // time_savers
    {
        to: '/features/delete-posts',
        icon: 'material-symbols:delete-rounded',
        name: 'Delete Posts',
        description: 'Delete posts directly from the app. No need to go to the social media platform.',
        category: 'time_savers',
    },

    {
        to: '/features/hashtag-lists',
        icon: 'mingcute:hashtag-fill',
        name: 'Hashtag Lists',
        description: 'Never miss a hashtag again. Create lists and use them in your posts with a click.',
        category: 'time_savers',
    },

    // branding
    {
        to: '/features/watermarks',
        icon: 'material-symbols:branding-watermark-outline-rounded',
        name: 'Watermarks',
        description: 'Show ownership of your content by adding a watermark to images and videos.',
        category: 'branding',
    },

    {
        to: '/features/signatures',
        icon: 'ph:signature-fill',
        name: 'Signatures',
        description: 'Be consistent and save time by appending signatures to your posts.',
        category: 'branding',
    },

    // apps_and_extensions
    {
        to: '/features/browser-extensions',
        icon: 'material-symbols:extension',
        name: 'Browser Extension',
        description: 'Did you see or read something interesting? Schedule it directly from your browser with a click.',
        category: 'apps_and_extensions',
    },

    {
        to: '/features/mobile-app',
        icon: 'material-symbols:phone-android',
        name: 'Mobile App',
        description: 'Do everything on the go. Schedule, publish, and engage with your audience from your phone.',
        category: 'apps_and_extensions',
    },
]
